







































































import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'Overlay',
  props: {
    isVisitor: {
      type: Boolean,
      default: false
    }
  },
  setup(props, ctx) {}
});
